export const GLOBAL_ENV_CONFIG_FIELD = {
  IS_DEV: 'isDev',
  IS_LOCAL_ENV: 'isLocalEnv',
  SOCKET_HOST: 'socketHost',
  API_HOST: 'apiHost',
  HOST: 'host',
  BASE_PATH: 'basePath',
} as const;

// Define the type of the keys
export type GlobalEnvConfigField =
  (typeof GLOBAL_ENV_CONFIG_FIELD)[keyof typeof GLOBAL_ENV_CONFIG_FIELD];

// Function overloads
export function getProcessEnvGlobalConfig(key: 'isDev' | 'isLocalEnv'): boolean;

export function getProcessEnvGlobalConfig(
  key: 'socketHost' | 'apiHost' | 'host' | 'basePath',
): string;

// Implementation
export function getProcessEnvGlobalConfig(key: GlobalEnvConfigField) {
  switch (key) {
    case GLOBAL_ENV_CONFIG_FIELD.IS_DEV:
      return typeof process !== 'undefined'
        ? process?.env?.NEXT_PUBLIC_IS_DEVELOPMENT === 'true'
        : true;
    case GLOBAL_ENV_CONFIG_FIELD.IS_LOCAL_ENV:
      return typeof process !== 'undefined'
        ? process?.env?.NEXT_PUBLIC_ENVIRONMENT === 'local'
        : true;
    case GLOBAL_ENV_CONFIG_FIELD.SOCKET_HOST:
      return typeof process !== 'undefined' ? process?.env?.NEXT_PUBLIC_SOCKET_HOST || '' : '';
    case GLOBAL_ENV_CONFIG_FIELD.API_HOST:
      return typeof process !== 'undefined' ? process?.env?.NEXT_PUBLIC_API_HOST || '' : '';
    case GLOBAL_ENV_CONFIG_FIELD.HOST:
      return typeof process !== 'undefined' ? process?.env?.NEXT_PUBLIC_HOST || '' : '';
    case GLOBAL_ENV_CONFIG_FIELD.BASE_PATH:
      return typeof process !== 'undefined' ? process?.env?.NEXT_PUBLIC_BASE_PATH || '' : '';
    default:
      throw new Error(`Unhandled global config ENV name: ${key}`);
  }
}
